.input-drop-file {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 120px;
	flex-direction: column;
	background-color: #799feb;
	margin-top: 10px;
	border-radius: 8px;

	#file-drag {
		width: 100%;
		height: 100%;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		border: 2px solid white;
		border-radius: 8px;
		padding: 10px;

		.fas, .fa {
			font-size: 3rem;
		}

		&.hover {
			border-style: dotted;
		}
	}
}

.input-drop-image {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	max-height: 300px;
	max-width: 450px;

	img {
		border-radius: 8px;
		object-fit: contain;
	}

	#image-drag {
		width: 100%;
		height: 100%;
		position: absolute;
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		border: 2px solid white;
		border-radius: 8px;

		&:hover {
			background-color: #00000036;
			border-style: dotted;

			.title-drop-image {
				color: #ffffff;
				background-color: rgba(0, 0, 255, 0.596);
				padding: 15px;
				p {
					color: white;
				}
			}
		}
	}
}
