.table-container {
    padding: 16px 24px;
    background-color: #d4d4d43b;
    color: white;
    border-radius: 4px;
    box-shadow: 0px 3px 11px #e8e8e8e3;
    height: 100%;

	.icon-title {
        padding-right: 8px;
    }

	table {
		width: 100%;
	}

	thead {
		width: 100%;
        i {
            font-size: $fontMini;
            padding: 7px 5px 5px;
            background: #ffffff61;
            border-radius: 4px;
            margin-right: 10px;
        }

        span {
            font-size: $fontRegular;
            font-weight: 500;
            letter-spacing: .5pt;
        }
    }

	tbody {
		width: 100%;
	}
     th {
        text-align: left;
        padding: 15px 6px;
        background-color: #92b5e0;
        color: white;
        font-family: "Quicksand", sans-serif;
        letter-spacing: .5pt;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: 500;
        border-bottom: 2px solid #92b5e0;
    }


    td {
        text-align: left;
        padding: 10px 8px;
        color: $gimav;
        font-family: $Raleway;
        letter-spacing: .5pt;
        font-size: $fontSmall;
        font-weight: 400;
        border-bottom: 2px solid #92b5e0;
    }
    .thumbnail {
        img {
            width: 40%;
        }
    }

    td img {
        width: 15%;
    }

    tr {
        width: 100%;
        background-color: transparent;
        transition: background-color 300ms ease-in-out;
    }

	.table-link {
		cursor: pointer;
        text-align: center;
        color: #fff;
        padding: 8px 11px;
        background-color: #3cb1bda6;
        font-weight: bold;
        font-size: 1.2rem;
        border-radius: 50%;
	}
}