.login {
	width: 100%;
	height: 100vh;

	form {
		width: 30%;
		min-height: 100px;
		background-color: #d8d7d7;
		padding: 20px;
		border-radius: 8px;

		@media screen and (max-width: 768px) {
			width: 60%;
		}
	}
}