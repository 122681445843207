header {
	width: 100%;
	height: 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	background-color: #f7f7f781;
	box-shadow: 2px 2px 2px #33333311;
	z-index: 2;
}