.preguntas {
	.pregunta {
		display: flex;
		flex-direction: column;
		background-color: white;
		border-radius: 8px;
		width: 100%;
		height: 220px;
		margin-bottom: 5px;
		padding: 10px;
		overflow: hidden;
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;

		.title {
			font-weight: bold;
		}

		.edit-buttons {
			position: absolute;
			right: 10px;
			z-index: 99;

			button {
				width: 80px;
			}
		}

		.nombre, .justificacion {
			display: flex;
		}

		.input {
			width: 60%;
		}

		.correcta {
			font-weight: bold;
			font-size: 1.1rem;
			color: green;
		}

		.image-pregunta-upload {
			height: 100%;
			max-height: 200px;
		}
	}

	table {
		width: 100%;

		thead {
			background-color: #259AD5;
			color: white;
			font-family: sans-serif;
			th {
				padding: 15px;
			}
		}

		td, th {
			text-align: left;
			padding: 5px;
		}

		tbody {
			background-color: #e4e4e4;
		}
	}
}

@media print {
	body {
		background-color: #2EACDF;
	}
	.side-bar {
		display: none;
	}
    .preguntas {
		.btn {
			display: none;
		}
		display: flex;
        background-color: white;
        height: 100%;
        width: 100%;
		.pregunta {
			border: 1px solid #2EACDF;
		}
    }
	table { page-break-after:auto }
	tr    { page-break-inside:avoid; page-break-after:auto }
	td    { page-break-inside:avoid; page-break-after:auto }
	thead { display:table-header-group }
	tfoot { display:table-footer-group }
	.pregunta {
		page-break-inside:always;
		page-break-after:always;
	}
}
