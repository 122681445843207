#ingreso_informacion {
    display: flex;
    flex-direction: column;

	@media screen and (max-width: 1024px) {
		padding-right: 0px;
	}

	
    section {
        overflow: auto;
        max-height: 300px;
		width: 100%;
        padding: 10px;
        display: flex;
		box-shadow: 2px 3px 5px #d8d8d822;
		background-color: #d8d8d822;
		margin-bottom: 10px;
		border-radius: 5px;

		input {
			margin-right: 10px;
		}

		.input-small {
			max-width: 20%;
		}

		.datos-interesantes {
			img {
				width: 32px;
				height: 32px;
			}
		}
    }
}

#imagenes-ilustrativas, #audios-complementarios, #videos-tematicos {
	display: flex;
	min-height: 200px;
	overflow: hidden;
	position: relative;

	.overflow {
		display: flex;
		overflow-x: auto;
		width: 100%;
	}

	.add-content {
		background-color: rgb(0, 177, 0);
		border-radius: 50px;
		height: 50px;
		padding: 8px;
		display: flex;
		align-items: center;
		justify-content: center;

		.fas {
			font-size: 2.5rem;
			color: white;
		}
	}

	.image-content {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 10px;
		padding: 10px;
		background-color: rgba(181, 181, 181, 0);
		border-radius: 8px;

		.imagen-upload {
			display: flex;
			cursor: pointer;
			width: 200px;
		}
	}

	.audio-content {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 70%;
		width: 250px;
		min-width: 250px;
		margin: 10px;
		padding: 10px;
		background-color: rgb(181, 181, 181);
		border-radius: 8px;
	}

	.video-content {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 250px;
		width: 250px;
		min-width: 250px;
		margin: 10px;
		padding: 10px;
		background-color: rgb(181, 181, 181);
		border-radius: 8px;
	}
}