.side-bar {
    background: #00225c;
    background: linear-gradient( 0deg, #00225c, #2EACDF);
    background: -webkit-gradient(linear,left bottom,left top,from(#00225c),to(#2EACDF));
    background: -webkit-linear-gradient(bottom,#00225c,#2EACDF);
    background: linear-gradient(0deg,#00225c,#2EACDF);
    width: 275px;
    height: 100vh;
    box-shadow: 4px 8px 10px 1px #000000;
    padding: 16px 0;
    position: sticky;
    top: 0;
    transition: all 300ms ease-in-out;

    .close-btn {
        position: absolute;
        right: 8px;
        top: 8px;
        padding: 8px;
        font-size: 1.6rem;
        display: none;
    }

    .profile-row {
        padding: 10px 0 25px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.719);

        .profile-data {
            min-width: 55%;
            p {
                font-size: .75rem;
                color: $white;

                &:first-child {
                    font-size: .9rem;
                }
            }
        }

        .profile-img {
            width: 60%;
            object-fit: cover;
            object-position: center;
        }
        
    }

    .nav-item {
        transition: all 300ms ease-in-out;
        cursor: pointer;

        a {
			padding: 16px 8px 16px 16px;
			&.active {
				background-color: #ffffff54;
			}
        }

        i, img {
            font-size: 1.4rem;
            margin-right: 14px;
        }
        span {
            font-size: $fontRegular;
            letter-spacing: 1.3pt;
        }

        &:hover {
            background-color: #ffffff54;
        }
    }
}

.btn-column {
	position: fixed;
    .btn-sidebar {
        position: absolute;
        font-size: 2rem;
        padding: 10px;
        top: 10px;
        display: none;
    } 
}

.open {
    left: 0px !important;
}

.children-view {
    padding: 24px 32px;
    overflow: hidden;
    width: 100%;
}

@media screen and (max-width: 1024px) { 
    .btn-column {
        .btn-sidebar {
			display: flex;
		}
    }
    
    .side-bar {
        width: 250px;
        position: fixed;
        height: 100vh;
        left: -250px;
        z-index: 1;

        .close-btn {
            display: flex;
        }
    }
}