body {
	background-color: #2EACDF;
}

.navigation {
	display: flex;
	position: absolute;
	top: 0px;
	left: 0px;
}

.page {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
}

.pointer {
	cursor: pointer;
}

.card-container {
	width: 100%;
	display: grid;
	padding: 10px;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 30px;

	.card {
		width: 100%;
		height: 200px;
		border-radius: 20px;
		background-color: white;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;

		.image-content {
			border-radius: 20px;
			width: 100%;
			height: 130px;
			overflow: hidden;

			&:hover {
				cursor: pointer;
				background-color: #2EACDF;
			}

			img {
				object-fit: cover;
			}
		}

		.title {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;

			h1 {
				font-size: 18px;
				color: black;
			}
		}
	}
}

.grid-container {
	width: 100%;
	display: grid;
	padding: 10px;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 30px;

	.grid-row, .grid-row-header {
		width: 100%;
		display: grid;
		padding: 10px;
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 10px;
	}

	.grid-row-header {
		background-color: #a7a7a7a2;

		h1 {
			font-size: 18px;
			color: black;
		}

		.grid-title {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.grid-row {
		cursor: pointer;
		background-color: #cececea2;
		align-items: center;

		h1 {
			font-size: 16px;
			color: black;
		}

		.grid-image {
			display: flex;
		}

		.grid-title {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.grid-action {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}
