.modal {
	position: fixed;
	width: 100%;
	height: 100%;
	z-index: 99;
	background-color: #00000067;
	top: 0px;
	left: 0px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;

	&.hidden {
		display: none;
	}

	.modal-content {
		width: 80%;
		max-width: 700px;
		background-color: rgb(199, 199, 199);
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		margin: auto;
		border-radius: 8px;
	}

	.modal-header {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 50px;
		background-color: #2CA6D7;

		.close {
			position: absolute;
			right: 10px;
			top: 10px;
			cursor: pointer;

			.fa {
				font-size: 2rem;
				text-shadow: 1px 1px 2px black;
			}
		}
	}

	.modal-body {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 400px;
		max-height: 600px;
		padding: 10px;
		overflow: auto;
	}
}

#imageModalView {
	.modal-content {
		background-color: transparent !important;
	}

	.modal-body {
		display: flex;
		justify-content: center;
		align-items: center;

		.image-content {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

#asignarTarjetaModal {
	.modal-content {
		border-radius: 8px;
	}

	.modal-body {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px;
		width: 100%;
		min-height: 150px;
	}
}

.modal-content, #imageModalViewCaption, #asignarTarjetaModalCaption {
	animation-name: zoom;
	animation-duration: 0.6s;
}

@keyframes zoom {
	from {transform:scale(0)}
	to {transform:scale(1)}
}